<template>
  <MainLayout :title="$t('explore')" :short-title="$t('staff.administration')" ::has-mobile-menu="true" back-to="/">
    <template v-slot:menu>
      <ExploreMenuTypes />
    </template>
    <template v-slot:content>
      <div id="applications" class="panel">
        <div class="panel-header">
          <div class="panel-header-title">
            <h1>{{ $t('staff.administration') }}</h1>
          </div>
        </div>
        <div class="panel-body">
          <div class="empty-hero-box">
            <p class="is-size-1">
              {{ $t('soonAvailable') }}
            </p>
            <p class="has-margin">
              As candidaturas de procedimentos concursais para Dirigentes ainda não estão disponíveis nesta plataforma.
            </p>
            <a href="https://www.uc.pt/drh/rm/pconcursais" target="_blank" class="button is-link is-outlined">
              <faicon icon="external-link-alt" /> {{ $t('procedure.institutionalWebsite') }}
            </a>
          </div>
        </div>
      </div>
    </template>
  </MainLayout>
</template>

<script>
import MainLayout from '@/components/layouts/MainLayout'
import ExploreMenuTypes from '@/components/menus/ExploreMenuTypes'

export default {
  components: {
    MainLayout,
    ExploreMenuTypes,
  },
}
</script>
